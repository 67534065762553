// System colors
$colors: (
	purple: #7b50a7,
	red: #d2604b,
	green: #9dcb5b,
	blue: #649fba,
	gold: #fbb910,
	teal: #34a861,
	orange: #f67419,
	dark-red: #cb695f,
	lavender: #a378e8,
	mint: #57d78d,
	brown: #875726,
	white: white,
	black: black
);

$bgColor: #f3f4fc;
