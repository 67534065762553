.icon-addon {
    position: relative;
    color: #555;
    display: block;

	&:after,
	&:before {
		display: table;
		content: " ";
	}

	&:after {
		clear: both;
	}

	.form-control:focus + .fa,
	&:hover .fa {
		color: #2580db;
	}

	.fa {
		position: absolute;
		z-index: 2;
		left: 10px;
		font-size: 14px;
		width: 20px;
		margin-left: -2.5px;
		text-align: center;
		padding: 10px 0;
		top: 1px
	}

	&.addon-lg {
		.form-control {
			line-height: 1.33;
			height: 46px;
			font-size: 18px;
			padding: 10px 16px 10px 40px;
		}
		.fa {
			font-size: 18px;
			margin-left: 0;
			left: 11px;
			top: 4px;
		}
	}

	&.addon-md .form-control,
	.form-control {
		padding-left: 30px;
		float: left;
		font-weight: normal;
	}

	&.addon-sm {
		.form-control {
			height: 30px;
			padding: 5px 10px 5px 28px;
			font-size: 12px;
			line-height: 1.5;
		}
		.fa {
			margin-left: 0;
			font-size: 12px;
			left: 5px;
			top: -1px
		}
	}
}
