.mat-dialog-actions {
	display: block !important;
    background: #f7f7f9;
    border-top: 1px solid #ddd;
    margin: 0 -24px;
    padding: 12px 24px !important;
	text-align: right;
	overflow:hidden;
}

.mat-dialog-title {
    background: #f7f7f9;
    margin: -24px !important;
    margin-bottom: 0 !important;
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.mat-dialog-content {
	padding: 24px !important;
}

.mat-tooltip {
	font-size: 0.75rem;
}

label.mat-button-toggle-label {
	margin-bottom:0;
}
