$hotHighlightColor: #5164f7;
@mixin hot-highlight {
	&:hover,
	&.highlighted {
		border:1px solid $hotHighlightColor;
	}
	&.highlighted {
		box-shadow: 0 5px 10px 2px #ccc;
		z-index: 10;
	}
}

@mixin hot-highlight-static {
	border-color:$hotHighlightColor !important;
	box-shadow: 0 5px 10px 2px #ccc;
	z-index: 10;
}
